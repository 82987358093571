import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// d.ts
import { IDataController, DCRecord } from "@/@types/lib/dataController"

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import DialogContext from "@/ui/DialogContext/DialogContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";

import dataController from "@/lib/dataController";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { DialogContextType } from "@/@types/ui/DialogContext";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { IModel } from "@/@types/models/model";
import { useSearchParams } from "react-router-dom";

type PhotoCentricTableProps = {
  onRowSelect: (id: number, record: DCRecord) => void;
  rowSelectedCustom?: number;
  onLoad?: (records: Array<DCRecord>) => void;

  titleToken: string,
  model: IModel
}

function PhotoCentricTable(props: PhotoCentricTableProps) {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const { t } = useTranslation();
  const { onRowSelect, onLoad, rowSelectedCustom  } = props;
  const { titleToken, model } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);

  const [searchParams] = useSearchParams();

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    if (searchParams.has("id")) {
      const id = Number(searchParams.get("id"))
      handleRowSelect(id);
    }
  }, [records]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        setRecords(resp.data)
        if (onLoad) {
          onLoad(resp.data);
        }
        if (resp.data.length === 0) {
          snackbarContext.showNotification("messages.nodata", "warning", true);
        }
      };
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowSelect = (id: number) => {
    const record = records.find(r => r.id === id);
    if (record !== undefined) {
      onRowSelect(id, record);
    }
  }

  return (
    <ModelTable
      title={t(titleToken)}
      viewName="processing"
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={false}
      allowAdd={false}
      allowEdit={false}
      disableControls={true}
      onRowAction={(id) => { console.log(id) }}
      onRowClick={handleRowSelect}
      initialPageSize={25}
      allowRowAction={() => { return true; }}
      trackTableState={false}
      unselectRow={true}
      rowSelectedCustom={rowSelectedCustom}
      gotoFirstPageOnSelect={true}
    />
  );
}

export default PhotoCentricTable;
