import React from "react";

// Custom Components
import HexPhotoCentricPage from "@/views/HexPhotoCentric/HexPhotoCentricPage";
import modelHeksagoni from "@/models/heksagoni";

type PaneliHexPhotoCentricPageParams = {}

function PaneliHexPhotoCentricPage(props: PaneliHexPhotoCentricPageParams) {
  return (
    <HexPhotoCentricPage
      titleToken={"titles.paneli"}
      model={modelHeksagoni}
      mapId={3}
      />
  );
}

export default PaneliHexPhotoCentricPage;
