import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import AppPage from "@/ui/AppPage/AppPage";
import HexPhotoCentricMiniMap from "./HexPhotoCentricMiniMap";
import HexPhotoCentricThumbs from "./HexPhotoCentricThumbs";
import useWindowSize from "@/components/useWindowSize";

//Types
import { DCRecord } from "@/@types/lib/dataController";
import { IImageData, } from "@/@types/views/KomunalniObveznici";
import { Size } from "@/components/useWindowSize";

import { IModel } from "@/@types/models/model";
import { Permission } from "@/@types/common";

type PhotoCentricPageParams = {
  needPermission?: [Permission]
  titleToken: string,
  model: IModel,
  mapId: number
}

function PhotoCentricPage(props: PhotoCentricPageParams) {
  const [selectedRecord, setSelectedRecord] = useState<DCRecord | null>(null);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<IImageData | null>(null);
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const size: Size = useWindowSize();

  const { needPermission, titleToken, model, mapId } = props;

  const handleRecordSelect = (id: number, record: DCRecord | null) => {
    // console.log(id, record);
    if (selectedRecord !== null && id === selectedRecord.id) {
      setSelectedRecord(null);
    } else {
      setSelectedRecord(record);
    }
    // if (!showImages && id) {
    //   setShowImages(true);
    // }
  };

  useEffect(() => {
    setShowImages(selectedRecord !== null);
  }, [selectedRecord]);

  const firstRowHeight = size.height ? `${(size.height - 50) * 0.6}px` : "60%";
  const secondRowHeight = size.height ? `${(size.height - 50) * 0.4}px` : "40%";

  return (
    <AppPage needPermission={needPermission}>
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box m={0} style={{ height: "calc(100% - 250px)" }}>
              <HexPhotoCentricMiniMap
                onObjektSelect={handleRecordSelect}
                selectedObjekt={selectedRecord}
                _setObjExtent={setObjExtent}

                mapId={mapId}
              />
          </Box>
          <Box sx={{ height: "250px", width: size.width ? (size.width - 88) : "100%" }}>
              <HexPhotoCentricThumbs
                baseRecordPath={model.apiPath}
                recordId={selectedRecord ? selectedRecord.id as number : null}
              />
          </Box>
        </Box>
    </AppPage>
  );
}

export default PhotoCentricPage;
