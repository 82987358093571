import React from "react";

//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelPaneli from "@/models/paneli";

type PaneliPhotoCentricPageParams = {}

function PaneliPhotoCentricPage(props: PaneliPhotoCentricPageParams) {
  return (
    <PhotoCentricPage
      titleToken={"titles.paneli"}
      model={modelPaneli}
      mapId={2}
      />
  );
}

export default PaneliPhotoCentricPage;
