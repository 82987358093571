import React, { useContext, useEffect, useRef, useState, SyntheticEvent, FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import OlFormatWKT from "ol/format/WKT";


//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import imgService from "@/services/imgService";

//Types
import { ClosingDetails } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { DialogContextType } from "@/@types/ui/DialogContext";
import { Skeleton } from "@mui/material";
import Loader from "@/ui/Loader/Loader";

type Clip = [number, number, number, number];
type Ratio = [number, number];
type Pair<A, B> = [A, B];
type Coordinates = Pair<number, number>[] | null;

const PT_RADIUS = 5;
const TRIANGLE_RADIUS = 15;
const IMAGE_WIDTH = 800;
const IMAGE_HEIGHT = IMAGE_WIDTH * 0.6666;
const MIN_WIDTH = 400;

const PhotoCentricImageDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();
  const [b64, setB64] = useState<string>('');
  const [ctx, setCtx] = useState<CanvasRenderingContext2D|null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { record, baseRecordPath, baseRecordId, onClose, hidePolygon, hideReddot } = props;

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: "cancel" });

  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const getCoordinates = (wkt: string) => {
    let coords: Coordinates = null;
    try {
      const WKT = new OlFormatWKT();
      const geom = WKT.readGeometry(wkt);
      //@ts-ignore
      coords = geom.getCoordinates()[0];
    } catch (err) {
      // pass
    }
    return coords;
  }

  const getClip = (coords: Coordinates) => {
    // let clip: number[] | null = null;
    const { width, height } = record;
    if (coords) {
      let minX = width * 2;
      let minY = height * 2;
      let maxX = -minX;
      let maxY = -minY;
      coords.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      const dx = maxX - minX;
      const dy = maxY - minY;
      let pad = Math.max(dx, dy);
      if (2 * pad < MIN_WIDTH) {
        pad = MIN_WIDTH - pad;
      }
      const bx = minX - pad/2;
      const by = minY - pad/2;
      const ex = maxX + pad/2;
      const ey = maxY + pad/2;

      // pan rect inside the image 
      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      // or dont pan
      // const incX = 0;
      // const incY = 0;
      // const decX = 0;
      // const decY = 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) { // x 
          const x = Math.round(n + incX - decX);
          // check if x is outside of image
          return x < 0 ? 0 : x > width ? width : x
        } else { // y
          const y = Math.round(n + incY - decY); 
          // check if y is outside of image
          return y < 0 ? 0 : y > height ? height : y
        }
      }) as Clip;

    }

    const { px, py } = record;
    if (px && py) {
      const ratio = height / width;
      const sz = width / 8;
      const bx = px - sz;
      const by = py - sz * ratio;
      const ex = px + sz;
      const ey = py + sz * ratio;

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) { // x 
          return Math.round(n + incX - decX);
        } else { // y
          return Math.round(n + incY - decY);
        }
      }) as Clip;      
    }

    return [0, 0, width, height] as Clip;
  }

  const getRatio = (clip: Clip, w: number, h:number) => {
    const [bx, by, ex, ey] = clip;
    const width = ex - bx;
    const height = ey - by;
    const wRatio = w / width;
    const hRatio = h / height;
    return [wRatio, hRatio] as Ratio;
  }

  const drawPolygon = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { poly, /*width, height*/ } = record;
    // console.log(poly)
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#00FF00";

    if (!record.poly) {
      return;
    }
    const coords = getCoordinates(poly);
    if (!coords) {
      return
    }
    const clip = getClip(coords);
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);
    
    for (let i = 0; i < coords.length; ++i) {
      const [_u, _v] = coords[i];
      const u = (_u - bx) * wRatio;
      const v = (_v - by) * hRatio;
      if (i === 0) {
        ctx.moveTo(u, v);
      }
      // ctx.setLineDash([5, 15]);
      ctx.lineTo(u, v);
    }

    ctx.closePath();
    ctx.stroke();
  }

  const drawPoint = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { px, py, poly } = record;
    // console.log(record)
    const coords = getCoordinates(poly);
    const clip = getClip(coords);
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#FF0000";
    ctx.fillStyle = "#FF0000";

    const u = (px - bx) * wRatio;
    const v = (py - by) * hRatio;
    ctx.arc(u, v, PT_RADIUS, 0, 2*Math.PI);

    ctx.fill();
  }

  const drawTriangle = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { px, py, poly, usjeverenje } = record;
    const coords = getCoordinates(poly);
    const clip = getClip(coords);
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#FF0000";
    ctx.fillStyle = "#FF0000";

    const u = (px - bx) * wRatio;
    const v = (py - by) * hRatio;

    const triangleHeight = TRIANGLE_RADIUS * Math.sqrt(3) / 2; // Height of an equilateral triangle

    ctx.translate(u, v); // Translate the context so the triangle is in the middle
    // Function takes the angle in Radians so we convert it from Degrees
    ctx.rotate(usjeverenje * Math.PI / 180); // Rotate the context by the specified angle

    ctx.moveTo(0, -(triangleHeight / 2 + 5)); // Top vertex
    ctx.lineTo(-TRIANGLE_RADIUS / 2, triangleHeight / 2); // Bottom left vertex
    ctx.lineTo(TRIANGLE_RADIUS / 2, triangleHeight / 2); // Bottom right vertex
    ctx.closePath(); // Close the path to complete the triangle

    ctx.rotate(-usjeverenje * Math.PI / 180); // Restore the context to its original rotation
    ctx.translate(-u, -v); 

    ctx.fill();
  }

  const onImgLoad = () => {
    let ctx: CanvasRenderingContext2D | null = null;
    const hasCanvas = canvasRef && canvasRef.current;
    if (hasCanvas) {
      ctx = canvasRef.current.getContext('2d');
    }
    const hasImg = imgRef && imgRef.current;
    if (hasCanvas && hasImg && ctx !== null) {
      canvasRef.current.width = imgRef.current.width;
      canvasRef.current.height = imgRef.current.height;
      ctx.drawImage(imgRef.current, 0, 0);
      !hidePolygon ? drawPolygon(ctx, imgRef.current.width, imgRef.current.height) : null;
      !hideReddot ? (
        record.usjeverenje 
          ? drawTriangle(ctx, imgRef.current.width, imgRef.current.height)
          : drawPoint(ctx, imgRef.current.width, imgRef.current.height)
        ) : null;
    }
  }

  useEffect(() => {
    const { id: imageId, poly, width, height } = record;
    const coords = getCoordinates(poly);
    const clip: Clip = getClip(coords);
    imgService.getObjImage(baseRecordPath, baseRecordId, imageId, IMAGE_WIDTH, clip).then(resp => {
      if (resp.success) {
        setLoaded(true);
        //@ts-ignore
        setB64(resp.data.image);
      }
    });
  }, [])

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.image")}</DialogToolbarHeading>
          <ToolbarFillContent />

          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={0} style={{ textAlign: "center" }}>
          {record && b64.length ?
            <Fragment>
              <img 
                ref={imgRef}
                src={`data:image/png;base64, ${b64}`} 
                style={{ display: "none" }}
                onLoad={() => onImgLoad()}
              />
              <canvas ref={canvasRef} style={{ margin: "16px" }}/>
            </Fragment>
            :
            <Skeleton variant="rectangular" width={IMAGE_WIDTH} height={IMAGE_HEIGHT} animation="wave" style={{ display: "inline-block", margin: "16px" }} />
          }
          {!loaded ?  <Loader open /> : null }
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
      </DialogActions>
    </DraggableDialog>
  );
}

export default PhotoCentricImageDialog;
