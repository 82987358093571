import React, { BaseSyntheticEvent } from "react";
import { TextField, Box, FormGroup, FormControlLabel, Checkbox, Switch } from "@mui/material"
import { useTranslation } from "react-i18next";

// MUI
import SearchIcon from '@mui/icons-material/Search';

//Types
import { ThumbFilters } from "./PhotoCentricThumbs";

type PhotoCentricThumbsFilterProps = {
  filterObject: ThumbFilters,
  onFilterChange: any,
  recordsLength: number,
  filteredRecrodsLength: number
}

function PhotoCentricThumbsFilter(props: PhotoCentricThumbsFilterProps) {
  const { filterObject, onFilterChange, recordsLength, filteredRecrodsLength } = props
  const { t } = useTranslation();

  function handleSetFilter(e: BaseSyntheticEvent) {
    const name = e.target.name

    let newFilter = { ...filterObject }
    if (name === "text") {
      newFilter.textFilter = e.target.value;
    } else if (name === "selected") {
      newFilter.selectedFilter = e.target.checked;
    } 
    
    onFilterChange(newFilter)
  }

  const hasRecords = recordsLength > 0 ? true : false;

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
      <TextField
        sx={{ ml: 3, mr: 3 }}
        variant='standard'
        margin='dense'
        name='text'
        value={filterObject.textFilter || ""}
        placeholder={t("common.search_count_placeholder", { count: filteredRecrodsLength })}
        InputProps={{
          startAdornment: <SearchIcon />
        }}
        onChange={handleSetFilter}
        disabled={!hasRecords}
      />
      {/* <FormControlLabel 
        control={<Switch checked={filterObject.selectedFilter} />} 
        disabled={!hasRecords} 
        name="selected" 
        label={t("buttons.filter_selected") as string} 
        onChange={handleSetFilter} 
      /> */}
    </Box>
  )
}

export default PhotoCentricThumbsFilter