import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Heksagoni: IModel = {
  title: "Heksagoni",
  apiPath: "hex",
  forms: {},
  listViews: {},
  fields: [
    coreFields.id,
    {
      title: "wkt",
      source: "wkt",
      ttoken: "common.wkt",
      type: "wkt",
      readonly: true
    }
  ],
}

export default Heksagoni;