import React, { useEffect, useState, useContext, useRef } from "react";

// MUI
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import { ImageList, ImageListItem, ImageListItemBar, Skeleton } from "@mui/material";

// MUI Icons
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from '@mui/icons-material/StarBorder';

// Custom Components

import modelImage from "@/models/images";
import dataController from "@/lib/dataController";
import UserContext from "@/components/UserContext/UserContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import NavDrawerContext from "@/components/NavDrawerContext/NavDrawerContext";
import DialogContext from "@/ui/DialogContext/DialogContext";
import ImageDialog from "./PhotoCentricImageDialog";
import imgService from "@/services/imgService";
import useWindowSize from "@/components/useWindowSize";
import PhotoCentricThumbsFilter from './PhotoCentricThumbsFilter';

//Types
import { DialogContextType } from "@/@types/ui/DialogContext";
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { DCRecord } from "@/@types/lib/dataController";
import { Size } from "@/components/useWindowSize";


const THUMB_WIDTH = 300;
const THUMB_HEIGHT = 225;
const REDDOT_RADIUS = 5;

export type ThumbFilters = {
  textFilter: string;
  selectedFilter: boolean;
}

type PhotoCentricThumbsProps = {
  baseRecordPath: string,
  recordId: number | null;
}

type IThumbsDictionary = {
  [key: number]: string
}

const PERCENTAGE = 5;

const initialFilterObject: ThumbFilters = {
  textFilter: "",
  selectedFilter: false
}

function PhotoCentricThumbs(props: PhotoCentricThumbsProps) {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const navDrawerContext = useContext(NavDrawerContext);

  const [filteredRecords, setFilteredRecords] = useState<DCRecord[]>([]);
  const [filterObject, setFilterObject] = useState<ThumbFilters>(initialFilterObject)
  const [records, setRecords] = useState<DCRecord[]>([]);
  const [thumbs, setThumbs] = useState<IThumbsDictionary>({});
  const size: Size = useWindowSize();

  const imageListRef = useRef(null);

  const { baseRecordPath, recordId } = props;

  const dc = new dataController(modelImage);

  useEffect(() => {
    if (recordId) {
      loadThumbnails();
    }
  }, [recordId])

  const loadThumbnails = () => {

    loaderContext.toggleLoading(true);
    const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1 ? true : false;
    dc.GetData(`${baseRecordPath}${endsWithSlash ? "" : "/"}${recordId}/images`).then((resp) => {
      if (resp.success) {
        const records = resp.data as DCRecord[];

        const filteredData = (records).filter(r => {
          const { width, height, px, py, poly } = (r as {
            width: number, height: number, px: number, py: number, poly: number
          });
          if (!px || !py || !width || !height) {
            return false;
          }

          if (r.selected) {
            return true;
          }
          
          const lower = PERCENTAGE / 100;
          const upper = (100 - PERCENTAGE) / 100;
          if ((px < (width * lower)) || (px > (width * upper))) {
            return false;
          }
          if (py < height * lower || py > height * upper) {
            return false;
          }

          return true;
        })

        // console.log(filteredData)

        // //HACK: ovakve provjere treba izbaciti...
        // if (userContext && (userContext.projektId === 21 || userContext.projektId === 17 || userContext.projektId === 4)) { //TODO: kad podaci budu spremni, ovo treba maknuti
        //   setRecords(records);
        // } else {
        //   setRecords(filteredData as DCRecord[]);
        // }

        //TODO: Check if this logic is valid
        const hasAtLeastOneRedPoint = records.find(x => x.px !== null && x.py !== null) !== undefined ? true : false;
        if (hasAtLeastOneRedPoint) {
          setRecords(filteredData as DCRecord[]);
        } else {
          setRecords(records);
        }
      }
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  }

  useEffect(() => {
    if (Array.isArray(records)) {
      records.forEach(x => {
        if (recordId && x.id && typeof x.id === "number") {
          imgService.getRecordImageThumb(baseRecordPath, recordId, x.id).then((base64) => {
            setThumbs((prevState) => {
              return {
                ...prevState,
                [x.id as number]: base64
              }
            })
          });
        }
      });
    }
  }, [records])

  useEffect(() => {
    if (records.length) {
      filterThumbnails()
    } else {
      setFilteredRecords(prev => prev.length ? [] : prev);
    }
    // setThumbs(prev => Object.keys(prev).length ? {} : prev);
  }, [filterObject, records])

  function filterThumbnails() {
    const newFilteredRecords = records.filter((record: any) => {
      let textOk = true;
      let selectedOk = true;

      // check text filter
      if (filterObject.textFilter.length > 0) {
        if (!(record.id.toString().toLowerCase().includes(filterObject.textFilter))) {
          textOk = false;
        }
      }

      //check selected filter
      if (filterObject.selectedFilter === true && record.selected === false) {
        selectedOk = false;
      }

      return textOk && selectedOk
    })
    setFilteredRecords(newFilteredRecords);
  }

  const handleThumbnailClick = (rec: DCRecord) => {
    //@ts-ignore
    // console.log(rec);

    dialogContext.showDialog(ImageDialog, {
      record: rec,
      baseRecordPath: baseRecordPath,
      baseRecordId: recordId,
      onClose: () => { },
      hidePolygon: true
    })

  }

  const handleMarkFavorite = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    //@ts-ignore
    // const imgId = parseInt(evt.target.id, 10);
    // const rec = records.find(x => x.id === imgId);
    // if (rec) {
    //   console.log(rec.selected);
    // }
  }

  const retrieveFile = (imageId: number) => {
    if (recordId && imageId) {
      return imgService.getRecordImageThumb(baseRecordPath, recordId, imageId)
    } else {
      return Promise.reject(null);
    }
  }

  const buildDataUrl = (imageId: number) => {
    if (thumbs[imageId] !== undefined) {
      return `data:image/jpg;base64,${thumbs[imageId]}`
    } else {
      return ''
    }
  }

  // const secondRowHeight = size.height ? (size.height - 50) * 0.4 : 500;
  // const mapWidth = secondRowHeight;
  // const imageListWidth = `${size.width ? (size.width - mapWidth - 100 - 240) : 600}px`
  // const imageListWidth = "100%";
  let dravWidth = 240
  {
    navDrawerContext?.sidebarOpen ?
      dravWidth = 240 :
      dravWidth = 60
  }

  const navWidth = (window.outerWidth - (dravWidth + 300))

  return (
    <Paper elevation={0}>
      <Box m={0} p={0} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
        <PhotoCentricThumbsFilter
          filterObject={filterObject}
          onFilterChange={setFilterObject}
          recordsLength={records.length}
          filteredRecrodsLength={filteredRecords.length}
        />
      </Box>
      <ImageList
        cols={filteredRecords.length}
        ref={imageListRef}
        sx={{
          width: "inherit"
          , overflowX: "auto"
          , overflowY: "hidden"
          , display: "block"
          , height: 255
          , whiteSpace: "nowrap"
          , maxWidth: navWidth
        }}>
        {filteredRecords.map(x => {
          if (x.thumb_path !== undefined) {
            // conversion of px/py from original photo to thumbnail
            // px - distance from the left edge of photo to reddot
            // py - distance from the top edge of photo to reddot
            // using the ratio of x_thumbnail/x_original we calculate px_thumbanail
            // the REDDOT_RADIUS / 2 is here because we want the center of the reddot 
            // to hit the coordinates and not the top left corner
            const px = x.px && x.width 
              ? Math.round(x.px as number * (THUMB_WIDTH / (x.width as number)) - (REDDOT_RADIUS / 2)) 
              : null;
            const py = x.py && x.height 
              ? Math.round(x.py as number * (THUMB_HEIGHT / (x.height as number)) - (REDDOT_RADIUS / 2)) 
              : null;

            return <ImageListItem key={x.id as string} onClick={() => handleThumbnailClick(x)} sx={{ width: THUMB_WIDTH, height: THUMB_HEIGHT, display: "inline-block", mr: 1 }}>
              {thumbs[x.id as number] === undefined ?
                <Skeleton animation="wave" variant="rectangular" width={THUMB_WIDTH} height={THUMB_HEIGHT} /> :
                <img id={x.id as string} src={buildDataUrl(x.id as number)} alt={x.serial as string} width={THUMB_WIDTH} height={THUMB_HEIGHT} />
              }
              {thumbs[x.id as number] !== undefined && px && py ?
                <Box m={0} sx={{
                  position: "absolute",
                  zIndex: 10,
                  width: 0,
                  height: 0,
                  border: REDDOT_RADIUS + "px solid red",
                  borderRadius: REDDOT_RADIUS + "px",
                  left: px + "px",
                  top: py + "px"
                }} ></Box> : null}

              <ImageListItemBar
                title={
                  x.path && String(x.path) && String(x.path).substring(String(x.path).lastIndexOf('/') + 1) 
                  // is there always a subfolder ?
                  ? String(x.path).substring(String(x.path).lastIndexOf('/') + 1) : null 
                }
                sx={{
                  ".MuiImageListItemBar-title": {
                    fontSize: "9px"
                  }
                }}
                // actionIcon={
                //   <IconButton
                //     sx={{ color: 'white' }}
                //     aria-label={`star ${x.path}`}
                //     onClick={handleMarkFavorite}

                //   >
                //     {x.selected ? <StarIcon id={x.id as string} /> :
                //       <StarBorderIcon id={x.id as string} />}
                //   </IconButton>
                // }
                // actionPosition="right"
              />

            </ImageListItem>
          } else {
            return null;
          }

        })}
      </ImageList>


    </Paper>
  )

}

export default PhotoCentricThumbs